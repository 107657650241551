import { CountUp } from "countup.js"

export default (index, countupValue, blockId) => ({
  init() {
    const findNumber = countupValue.match(/\d+/g)

    if (!findNumber) return null

    const value = findNumber[0] || null

    const indexStart = countupValue.indexOf(value)
    const indexEnd = indexStart + value.length

    const prefix = countupValue.slice(0, indexStart)
    const suffix = countupValue.slice(indexEnd)

    const counter = new CountUp(
      `countup-heading-${index}-${blockId}`,
      value,
      { enableScrollSpy: true, scrollSpyOnce: true, scrollSpyDelay: 300, duration: 3, prefix, suffix },
    )

    counter.start()
  },
})
