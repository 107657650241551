import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default () => ({
  scrollTriggerStart: null,
  disableScrollTrigger: false,
  init() {
    const pageHeaderEl = this.$el
    this.calculateScrollTriggerStart(pageHeaderEl)

    if (this.disableScrollTrigger) {
      return
    }

    gsap.to(pageHeaderEl, {
      scrollTrigger: {
        trigger: pageHeaderEl,
        start: this.scrollTriggerStart,
        pin: true,
        pinSpacing: false,
      },
    })
  },
  calculateScrollTriggerStart(pageHeaderEl) {
    const pageHeaderHeight = pageHeaderEl.offsetHeight
    const headerHeight = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        "--header-height",
      ) * 16,
    )
    const viewportHeight = window.innerHeight - headerHeight

    this.disableScrollTrigger = pageHeaderHeight >= window.innerHeight

    this.scrollTriggerStart =
      pageHeaderHeight >= viewportHeight ? "bottom bottom" : "top top"
  },
})
