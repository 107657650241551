export default () => ({
  handleClick(id) {
    const target = document.getElementById(id)
    const headerHeight = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        "--header-height",
      )
    )
    const buffer = 32
    const targetPosition = target.getBoundingClientRect().top
    const offsetPosition = (targetPosition + window.pageYOffset - buffer) - (headerHeight * 16)

     window.scrollTo({
       top: offsetPosition,
       behavior: "smooth",
     })
  }
})
