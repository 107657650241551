import lottie from "lottie-web"
import { create } from "@lottiefiles/lottie-interactivity"
import { replaceColor } from "lottie-colorify"
import lines from "../public/lottie/footer-lines.json"

export default featureColour => ({
  footerAnimation: null,
  init() {
    ;;(this.footerAnimation = lottie.loadAnimation({
      container: this.$el,
      renderer: "svg",
      autoplay: false,
      loop: false,
      animationData: replaceColor("#FF361E", featureColour, lines),
    })),
      create({
        mode: "scroll",
        player: this.footerAnimation,
        container: ".footer-lottie-container",
        actions: [
          {
            visibility: [0.2, 0.3],
            type: "playOnce",
          },
        ],
      })
  },
})
