import lottie from "lottie-web"
import { create } from "@lottiefiles/lottie-interactivity"
import { replaceColor } from "lottie-colorify"
import lines from "../public/lottie/lines.json"

export default (featureColour, id) => ({
  animation: null,
  init() {
    ;(this.animation = lottie.loadAnimation({
      container: this.$el,
      renderer: "svg",
      autoplay: false,
      loop: false,
      animationData: replaceColor("#FF361E", featureColour, lines),
    })),
      create({
        mode: "scroll",
        player: this.animation,
        container: `.lottie-container-${id}`,
        actions: [
          {
            visibility: [0, 0.3],
            type: "stop",
            frames: [0],
          },
          {
            visibility: [0.3, 0.7],
            type: "seek",
            frames: [0, 399],
          },
        ],
      })
  },
})
