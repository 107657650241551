export default (initialThemeState = "light") => ({
  sectionTheme: initialThemeState,
  pageTheme: initialThemeState,
  setTheme(theme) {
    this.sectionTheme = theme
    if (this.sectionTheme === "dark") {
      document.documentElement.style.setProperty("--primary", `#FCFCFC`)
      document.documentElement.style.setProperty("--secondary", `#1C1C1C`)
    } else {
      document.documentElement.style.setProperty("--primary", `#1C1C1C`)

      if (this.sectionTheme === "light") {
        document.documentElement.style.setProperty("--secondary", `#FCFCFC`)
      } else {
        document.documentElement.style.setProperty("--secondary", `#EAEAEA`)
      }
    }
  },

  init() {
    const themeEls = document.querySelectorAll("[data-theme]")

    setTimeout(() => {
      // prevent transition on page load
      document.body.classList.remove("theme-is-loading")
    }, 1500)

    let observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const { theme } = entry.target.dataset
          if (entry.isIntersecting) {

            if (this.sectionTheme == theme) {
              return
            }

            this.setTheme(theme)
          }
        })
      },
      {
        threshold: 0,
        rootMargin: "-50% 0% -50% 0%",
      },
    )

    themeEls.forEach(el => {
      observer.observe(el)
    })
  },
})
