import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

export default (hasImageHero = "false") => ({
  menuOpen: false,
  showHeaderBg: false,
  prevScroll: 0,
  headerHeight: 0,
  hasImageHero: hasImageHero,
  toggleMenu() {
    const compactMenuEl = this.$refs["compact-menu"]

    if (this.menuOpen) {
      enableBodyScroll(compactMenuEl)
      return (this.menuOpen = false)
    }

    disableBodyScroll(compactMenuEl)
    this.menuOpen = true
  },
  windowResize() {
    if (window.innerWidth > 1024 && this.menuOpen) {
      clearAllBodyScrollLocks()
      return (this.menuOpen = false)
    }
  },
  handleScroll() {
    if (this.hasImageHero) {
      this.addHeaderBackgroundColour()
    }

    const currentScroll = window.pageYOffset
    if (this.prevScroll > currentScroll) {
      this.$el.style.top = "0"
    } else {
      this.$el.style.top = `-${this.headerHeight + 2}px`
    }
    this.prevScroll = currentScroll <= 0 ? 0 : currentScroll
  },
  addHeaderBackgroundColour() {
    const screenHeight = window.innerHeight

    const heroHeight = screenHeight - this.headerHeight

    if (window.scrollY > heroHeight) {
      this.showHeaderBg = true
    } else {
      this.showHeaderBg = false
    }
  },
  setVhProperty() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  },
  init() {
    this.setVhProperty();
    this.headerHeight =
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          "--header-height",
        ),
      ) * 16

    if (this.hasImageHero) {
      this.addHeaderBackgroundColour()
    }
  },
})
