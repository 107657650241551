import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

import { gsap } from "gsap"

export default () => ({
  init() {
    const preloaderEl = this.$el

    if (sessionStorage.getItem("preloader") === "true") {
      preloaderEl.remove()
      return
    }

    const preloaderTimeline = gsap.timeline()
    disableBodyScroll(preloaderEl)

    const lettersWrapperEl = this.$refs["letters-wrapper"]
    const imagePrimaryWrapperEl = this.$refs["image-primary-wrapper"]
    const imageSecondaryWrapperEl = this.$refs["image-secondary-wrapper"]
    const imagesEl = this.$refs["images"]

    if (!!imagePrimaryWrapperEl) {
      preloaderTimeline
        .addLabel("fade")
        .to(
          imagePrimaryWrapperEl,
          {
            opacity: 1,
            duration: 0.5,
            ease: "power2.out",
          },
          "fade",
        )
        .to(
          lettersWrapperEl,
          {
            opacity: 1,
            duration: 0.5,
            ease: "power2.out",
          },
          "fade",
        )
        .to(imagesEl, {
          x: "-50%",
          duration: 1,
          delay: 0.5,
          ease: "power2.inOut",
        })
        .to(imageSecondaryWrapperEl, {
          opacity: 1,
          duration: 1,
          delay: 0.8,
          ease: "power2.out",
        })
    } else {
      preloaderTimeline.to({}, { duration: 1 })
    }

    preloaderTimeline
        .addLabel("scale-fade")
        .to(
          imageSecondaryWrapperEl,
          {
            scale: 1,
            duration: 0.8,
            ease: "power2.out",
          },
          "scale-fade",
        )
        .to(
          lettersWrapperEl,
          {
            opacity: 0,
            duration: 0.5,
            ease: "power2.out",
          },
          "scale-fade",
        )
        .to(preloaderEl, {
          opacity: 0,
          duration: 0.8,
          ease: "power2.inOut",
          onComplete() {
            clearAllBodyScrollLocks()
            preloaderEl.remove()
            sessionStorage.setItem("preloader", "true")
          },
        })
    }
  })
