export default () => ({
  isInView: false,
  init() {

    if (this.isInView) {
      return
    }

    const featureTextEl = this.$el;

    let observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.isInView = true
          }
        })
      },
      {
        threshold: 1,
      },
    )

    observer.observe(featureTextEl)
  }
})
