import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

export default () => ({
  selectedCategory: "",
  selectedPerson: "",
  handleCategoryClick(category) {
    this.selectedCategory = category
    this.selectedPerson = ""
    this.updateUrlParams()
  },
  handlePersonClick(person) {
    this.selectedPerson = person
    this.updateUrlParams()

    if (person === "") {
      clearAllBodyScrollLocks()
    } else {
      const personModalEl = this.$refs[`modal-${person}`]
      disableBodyScroll(personModalEl)
    }
  },
  updateUrlParams() {
    const nameParam =
      this.selectedPerson === "" ? "" : "name=" + this.selectedPerson

    const teamParam =
      this.selectedCategory === "" ? "" : "team=" + this.selectedCategory

    let params

    if (nameParam !== "" && teamParam !== "") {
      params = `meet-the-team?${teamParam}&${nameParam}`
    } else if (nameParam === "" && teamParam !== "") {
      params = `meet-the-team?${teamParam}`
    } else if (nameParam !== "" && teamParam === "") {
      params = `meet-the-team?${nameParam}`
    } else {
      params = "meet-the-team"
    }
    window.history.pushState(null, null, "/meet-the-team")
    window.history.replaceState(null, null, params)
  },
  init() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })

    if (params && params.team) {
      this.selectedCategory = params.team
    }
    if (params && params.name) {
      this.selectedPerson = params.name
      const personModalEl = this.$refs[`modal-${this.selectedPerson}`]
      disableBodyScroll(personModalEl)

      if (params.download !== null) {
        window.location.href = personModalEl.dataset.vCard
      }
    }

    window.addEventListener("popstate", () => {
      if (window.location.pathname === "/meet-the-team") {
        this.selectedCategory = ""
        this.selectedPerson = ""
        clearAllBodyScrollLocks()
      }
    })
  },
})
